<template>
  <v-container fluid class="px-6 py-6">
    <v-row justify="end">
      <v-col lg="8" cols="12" md="8" v-if="colIgnore">
        <v-card class="border-radius-xl card-shadow">
          <div class="d-flex flex-row pb-0 px-4 py-4" v-if="lesson">
            <h4
              class="text-h4 my-auto text-typo font-weight-bold"
              style="white-space: nowrap"
            >
              {{ lesson.courseInfo.COURSE_NAME }},
              <span class="blue--text"> {{ _getClassName() }}</span>
            </h4>
            <v-spacer></v-spacer>
            <span class="text-typo">
              Зааx xичээлийн цаг:
              <span class="blue--text">{{
                lesson.courseInfo.COURSE_CONTACT_HOURS
              }}</span></span
            >
          </div>
          <div class="px-4 py-4 mt-2"></div>
        </v-card>
      </v-col>
      <v-col lg="4" md="4" cols="12">
        <v-card class="card-shadow border-radius-xl">
          <div class="px-4 pt-4">
            <div class="d-flex flex-column">
              <v-row>
                <v-col cols="10">
                  <v-row justify="space-between">
                    <v-col cols="12" class="pb-0">
                      <strong
                        >Сурагчийн тоо:
                        <span v-if="students" class="blue--text">{{
                          students.length
                        }}</span></strong
                      >
                    </v-col>
                  </v-row>
                </v-col>
                <v-progress-linear
                  v-if="loadingStudents"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </v-row>
            </div>
          </div>
          <v-sheet
            max-height="600"
            min-height="400"
            style="overflow-y: scroll"
            class="mt-4"
          >
            <v-tabs v-model="selectedTab">
              <v-tab>Сурагчид</v-tab>
            </v-tabs>
            <v-tabs-items v-model="selectedTab">
              <v-tab-item>
                <v-simple-table v-if="students">
                  <template v-slot:default>
                    <tbody>
                      <!-- @click="_showRemoveStudentDialog(student)" -->
                      <tr
                        v-for="(student, i) in students"
                        :key="'student-' + student.id + '-' + i"
                        style="cursor: pointer"
                      >
                        <td
                          :class="{ 'border-bottom': i != students.length - 1 }"
                          style="width: 1%"
                        >
                          {{ i + 1 }}
                        </td>
                        <td>
                          {{ student["FIRST_NAME"] }},
                          <span class="grey--text">{{
                            student["LAST_NAME"]
                          }}</span>
                        </td>
                        <td>{{ student["GENDER_CODE"] }}</td>
                        <td class="blue--text">
                          {{ student["STUDENT_GROUP_NAME"] }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-tab-item>
            </v-tabs-items>
          </v-sheet>
        </v-card>
        <v-card
          class="pa-0 border-radius-lg mt-7 mb-9"
          :style="`background-image: url(${require('../../assets/img/curved-images/white-curved.jpeg')}); background-size: cover;`"
        >
          <span
            class="mask opacity-8 border-radius-lg"
            :class="`bg-gradient-warning`"
          ></span>
          <div class="px-4 py-4 position-relative">
            <div class="docs-info">
              <v-card-text class="text-white text-h6 ps-0 pb-2 font-weight-bold"
                >QR code</v-card-text
              >
              <v-card-subtitle class="text-white text-xs font-weight-bold">
                <v-row>
                  <v-col lg="6">
                    <VueQRCodeComponent
                      :size="100"
                      text="Text to encode"
                    ></VueQRCodeComponent>
                  </v-col>
                  <v-col lg="6">
                    Энэxүү кодын тусламжтай xичээл рүү шууд нэвтрэx боломжтой.
                  </v-col>
                </v-row>
              </v-card-subtitle>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/*eslint-disable*/
import moment from "moment";
import TopicTable from "./Widgets/TopicTable.vue";
import VueQRCodeComponent from "vue-qrcode-component";
import myfunction from "@/components/global/myfunction.js";
import StudentsTable from "../Pages/Profile/Widgets/StudentsTable.vue";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
import axios from "axios";
import esisAPIS from "@/_esis/EsisUtil.js";
export default {
  name: "smart-home",
  components: {
    TopicTable,
    VueQRCodeComponent,
  },
  mixins: [myfunction],
  data() {
    return {
      subLessons: null,
      selectedTeacher: null,
      loading: false,
      groupStudents: null,
      selectedTab: 0,
      loadingStudents: false,
      students: null,
      lesson: null,
      colIgnore: true,
      colWidth: 4,
      search: "",
      stQuery: null,
      stPath: null,
      stHeaderNames: null,
      stBodyNames: null,
    };
  },
  props: {
    path: {
      type: String,
      required: false,
    },
    selectedCourseId: {
      type: Number,
      required: false,
    },
    isCollectedLesson: {
      type: Boolean,
      required: false,
    },
    selectedTeacherRefPath: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...sync("*"),
    allStudents() {
      var list = [];
      for (const sll of this.subLessons) {
        if (sll) {
          sll.lessonGroups.forEach((gg) => {
            if (gg.students) {
              gg.students.forEach((ss) => {
                console.log(ss);
              });
            }
          });
        }
      }
    },
  },
  methods: {
    _getClassName() {
      var xx = null;
      if (this.subLessons) {
        this.subLessons.forEach((subLesson) => {
          xx =
            xx? (xx + ", " +
            (subLesson.classGroups && subLesson.classGroups[0]
              ? subLesson.classGroups[0].classGroupName
              : "")): (subLesson.classGroups && subLesson.classGroups[0]
              ? subLesson.classGroups[0].classGroupName
              : "")
        });
      }
      return xx;
    },
  },

  created() {
    console.log(this.isCollectedLesson, this.selectedTeacherRefPath);
    console.log(this.userData.school, this.selectedCourseId);
    fb.db
      .doc(this.selectedTeacherRefPath)
      .get()
      .then((doc) => {
        let teacher = doc.data();
        teacher.id = doc.id;
        teacher.ref = doc.ref;
        this.selectedTeacher = teacher;

        if (
          this.isCollectedLesson &&
          this.selectedCourseId &&
          this.userData.school
        ) {
          this.loadingStudents = true;
          this.userData.school.ref
            .collection("lessons-"+this.userData.school.currentYear)
            .where("courseInfo.COURSE_ID", "==", this.selectedCourseId)
            .where("teacherRefs", "array-contains", this.selectedTeacher.ref)
            .get()
            .then((docs) => {
              this.subLessons = [];
              this.students = [];
              docs.docs.forEach((doc) => {
                let subLesson = doc.data();
                subLesson.id = doc.id;
                subLesson.ref = doc.ref;
                subLesson.lessonGroups = null;
                if (!this.lesson) this.lesson = subLesson;

                subLesson.ref
                  .collection("lesson-groups")
                  .where("teacher.teacherId", "==", this.selectedTeacher.id)
                  .onSnapshot((docs) => {
                    subLesson.lessonGroups = [];
                    docs.forEach((doc) => {
                      let gg = doc.data();
                      gg.id = doc.id;
                      gg.ref = doc.ref;
                      gg.students = null;
                      gg.ref.collection("students").onSnapshot((docs) => {
                        gg.students = [];
                        docs.forEach((doc) => {
                          let ss = doc.data();
                          ss.id = doc.id;
                          ss.ref = doc.ref;
                          gg.students.push(ss);
                          this.students.push(ss);
                        });
                      });
                      subLesson.lessonGroups.push(gg);
                    });
                    this.loadingStudents = false;
                  });

                this.subLessons.push(subLesson);
              });
            });
        }
      });
    this.stHeaderNames = [
      {
        text: "ID",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Хүйс",
        align: "start",
        sortable: true,
        value: "genderName",
      },
      {
        text: "Анги",
        align: "start",
        sortable: true,
        value: "className",
      },
    ];
    this.stBodyNames = ["name", "lastName", "genderName"];
  },
  mounted() {},
  filters: {
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "-";
    },
  },
};
</script>

<style>
.xeelj-table-hcell {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
  width: 100%;
}
.xeelj-table-cell {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  width: 100%;
  cursor: pointer;
}
.hoverbutton-:hover {
  color: green !important;
  font-weight: bold;
}
.hoverbutton--:hover {
  color: red !important;
  font-weight: bold;
}
</style>
